import Helmet from "react-helmet"
import React from "react"
import LearnMore from "src/components/learnmore"
import HomepageLayout from "src/layouts/homepage"
import {isIeEdge,isChrome,isFirefox} from "@tightrope/lpscripts/browserdetect"


const LearnMoreData = import("/src/components/learnmore/data/learnmore.json");

export default function HomePage() {

  return(
    <HomepageLayout>
      <Helmet>

      <title>Free File Conversions - frompdftodoc.com</title></Helmet>
      <LearnMore data={LearnMoreData}>
      </LearnMore>
    </HomepageLayout>
  )
}
