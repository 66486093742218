import React from "react"
import LearnMoreData from "./data/learnmore.json"

import Styles from "./css/learnmore.module.scss"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"
class LearnMore extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      display: 'none',
      pageData: props.data,
      data: LearnMoreData
    }
  }

  headline(data){
    let params = new URLSearchParams(window.location.search);
    let hc = params.get("hc");
    let kw = params.get("kw");
    let bn = params.get("bn");
    let qsData = {};
    if(hc){
      qsData.headline = hc;
    }
    if(kw){
      qsData.subhead = kw;
    }
    if(bn){
      qsData.mainCTA = bn;
    }
    if(qsData){
      const queryData = Object.assign(data, qsData);
      this.setState({data: queryData});
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
    this.state.isChrome = isChrome();
    this.state.isFirefox = isFirefox();
    this.state.isIeEdge = isIeEdge();

    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
    this.headline(this.state.data);
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    });
  }

  render() {
    let intro;
    if (this.state.data.introType === 'video') {
      intro = <video className={Styles.centerimage} loop autoPlay muted><source src={this.state.data.introMedia}></source></video>;
    }
    else if (this.state.data.introType === 'image') {
      intro = <img src={this.state.data.introMedia} className={Styles.centerimage} />;
    }
    else{
      intro = null;
    }
    let second;
    if (this.state.data.secondType === 'video') {
      second = <video className={Styles.secondVideo} loop autoPlay muted><source src={this.state.data.secondMedia}></source></video>;
    }
    else if (this.state.data.secondType === 'image') {
      second = <img src={this.state.data.secondMedia} className={Styles.secondimage} />;
    }
    else{
      second = null;
    }
    return (
      <div className = {Styles.extended}  style={{ display: this.state.display }} >
      <div className = {Styles.header}><img className = {Styles.toplogo} src={this.state.data.logo}/><span className = {Styles.pr}></span>

      	<span className={Styles.pt}>{this.state.data.productType}
      </span>
      	</div>

      	<div className = {Styles.clear}></div>

      <div className = {Styles.allContainer}>


      <div className = {Styles.middle}>
      <div className = {Styles.middleWrapper}>


        <div className = {Styles.anchorlinks}>
          <a href="#easy-save-extension"><span dangerouslySetInnerHTML={{ __html: this.state.data.productName}}></span></a>
          <a href="#how-to-use-easy-save-extension">How to Use <span dangerouslySetInnerHTML={{ __html: this.state.data.productName}}></span></a>

        </div>

      	<div className = {Styles.bottomInner}>
          <a id="easy-save-extension"></a>
          <h2 className = {Styles.headline}>{this.state.data.topHeadline}</h2>
          <p className = {Styles.subHeadp} dangerouslySetInnerHTML={{ __html: this.state.data.midSubhead}}></p>


          <p dangerouslySetInnerHTML={{ __html: this.state.data.description}}></p>

          <a id="how-to-use-easy-save-extension"></a>
          <h2 className = {Styles.headline}>{this.state.data.midHeadline}</h2>

          <p className = {Styles.subHeadp} dangerouslySetInnerHTML={{ __html: this.state.data.feature}}></p>

          <ol dangerouslySetInnerHTML={{ __html: this.state.data.featureList}}></ol>


          <p dangerouslySetInnerHTML={{ __html: this.state.data.descriptionBottom}}></p>






        </div>

        </div>
      </div>{/*end middle*/}


      </div> {/*end allContainer*/}

      <div className ={Styles.br} style={{ display: this.state.display }}>
        Tightrope Interactive
          | <a href="/terms-of-service/" target="_blank">Terms</a> | <a href="/privacy-policy/" target="_blank">Privacy</a> | <a href="/uninstall/" target="_blank">Uninstall</a><a href="/unsubscribe/" target="_blank">Unsubscribe</a> | <a href="/contact-us/" target="_blank">About Us</a> <br />
          All trademarks are property of their respective owners  <a href="/privacy-policy/#cali-consumer" target="_blank">Do Not Sell My Personal Information</a>
      </div>

    </div>



    )
  }
}
export default LearnMore
